// Generated by Framer (c93245f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["YpOHFn2NV"];

const serializationHash = "framer-EwgAq"

const variantClassNames = {YpOHFn2NV: "framer-v-l1rt5c"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, tagTitle, width, ...props}) => { return {...props, FTeGC6_iK: tagTitle ?? props.FTeGC6_iK ?? "Tag"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tagTitle?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, FTeGC6_iK, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "YpOHFn2NV", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-l1rt5c", className, classNames)} data-framer-name={"Wrap"} layoutDependency={layoutDependency} layoutId={"YpOHFn2NV"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-0109aa55-a8db-4917-9953-a1fb1bfad426, rgb(255, 255, 255))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-font-weight": "500", "--framer-line-height": "1.8em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-e065af15-a162-4041-821a-20e240d9ad3d, rgb(0, 0, 0)))"}}><motion.strong>Tag</motion.strong></motion.p></React.Fragment>} className={"framer-1ldnqfn"} fonts={["GF;Inter-500", "GF;Inter-700"]} layoutDependency={layoutDependency} layoutId={"s8Z9wYVB1"} style={{"--extracted-r6o4lv": "var(--token-e065af15-a162-4041-821a-20e240d9ad3d, rgb(0, 0, 0))"}} text={FTeGC6_iK} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-EwgAq.framer-pkuz3s, .framer-EwgAq .framer-pkuz3s { display: block; }", ".framer-EwgAq.framer-l1rt5c { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; padding: 10px 20px 10px 20px; position: relative; width: min-content; }", ".framer-EwgAq .framer-1ldnqfn { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-EwgAq.framer-l1rt5c { gap: 0px; } .framer-EwgAq.framer-l1rt5c > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-EwgAq.framer-l1rt5c > :first-child { margin-left: 0px; } .framer-EwgAq.framer-l1rt5c > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 41
 * @framerIntrinsicWidth 61
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"FTeGC6_iK":"tagTitle"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerl2kX1GXZ9: React.ComponentType<Props> = withCSS(Component, css, "framer-EwgAq") as typeof Component;
export default Framerl2kX1GXZ9;

Framerl2kX1GXZ9.displayName = "Tag / White";

Framerl2kX1GXZ9.defaultProps = {height: 41, width: 61};

addPropertyControls(Framerl2kX1GXZ9, {FTeGC6_iK: {defaultValue: "Tag", displayTextArea: false, placeholder: "", title: "Tag Title", type: ControlType.String}} as any)

addFonts(Framerl2kX1GXZ9, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZ1rib2Bg-4.woff2", weight: "500"}, {family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZ1rib2Bg-4.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})